import makeUpperCase from "./makeUpperCase";
import { DateTime } from "luxon";

const getLocalTimeChat = (locale, time, day) => {
  // Group by Date
  if (day) {
    // Older than a week
    if (DateTime.fromISO(time).diffNow().as("days") < -7) {
      return makeUpperCase(
        DateTime.fromISO(time)
          .setLocale(locale)
          .toLocaleString({ weekday: "long", month: "long", day: "2-digit" })
      );
    }
    // Within a week
    else {
      return makeUpperCase(
        DateTime.fromISO(time)
          .setLocale(locale)
          .toRelativeCalendar({ unit: "days" })
      );
    }
  }
  // Only hours and minutes - DEAFULT
  return DateTime.fromISO(time).setLocale(locale).toFormat("HH:mm");
};

export default getLocalTimeChat;
