/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable no-useless-escape */
import errorToast from "../../../libs/errorToast";
import getTranslatedText from "../../../libs/getTranslatedText";
import supabase from "../../../supabase/client";
import ErrorToasterChat from "./ErrorToasterChat";
import { Attach, Send } from "akar-icons";
import axios from "axios";
import { LazyMotion, domAnimation, m } from "framer-motion";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";

const ChatboxInput = ({ supabaseUser, user, chatId, locale, state }) => {
  const [input, setInput] = useState("");
  const [typing, setTyping] = useState(false);
  const [inputActive, setInputActive] = useState(false);

  const inputRef = useRef();
  const formRef = useRef();

  // Animation variants
  const formVariants = {
    hidden: { opacity: 0, y: 5 },
    show: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  };

  // Control the text if it's link or not
  const linkify = (inputText) => {
    var replacedText, replacePattern1, replacePattern2;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 =
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(
      replacePattern1,
      '<a href="$1" target="_blank">$1</a>'
    );

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(
      replacePattern2,
      '$1<a href="http://$2" target="_blank">$2</a>'
    );

    return replacedText;
  };

  // Send message handler
  const messageHandler = async (e) => {
    e.preventDefault();
    setInput("");
    setUploading(true);

    if (input.trim(" ").length > 0) {
      try {
        if (!state.translation) {
          const { data, error } = await supabase.from("messages").insert({
            message: `<div>${linkify(input)}</div>`,
            chat_id: chatId,
            user_id: supabaseUser.id,
            avatar: user?.avatar || "992e52cb-eb9e-4081-9c1a-19355c8c9045",
          });
          if (data && !error) {
            // Increment new message count
            await supabase.rpc("increment_new_message_agent", {
              row_id: chatId,
            });
          }
        } else {
          const translatedInput = await axios.post("/api/translate/message", {
            text: input,
            target: "tr",
          });

          const { data, error } = await supabase.from("messages").insert({
            message: `<div>${linkify(input)}</div>`,
            translated_message: `<div>${linkify(translatedInput?.data)}</div>`,
            chat_id: chatId,
            user_id: supabaseUser.id,
            avatar: user?.avatar || "992e52cb-eb9e-4081-9c1a-19355c8c9045",
          });
          // Increment new message count
          if (data && !error) {
            await supabase.rpc("increment_new_message_agent", {
              row_id: chatId,
            });
          }
        }

        // Style input
        setUploading(false);
        inputRef.current.style.height = "inherit";
        inputRef.current.style.height = "auto";
      } catch (error) {
        console.log(error?.response || error);
        errorToast(locale);
      }
    } else {
      return;
    }
  };

  // Input Height Handler
  useEffect(() => {
    if (input.length > 0) {
      inputRef.current.style.height = "inherit";
      inputRef.current.style.height = `${Math.min(
        inputRef.current.scrollHeight,
        200
      )}px`;
    } else {
      inputRef.current.style.height = "inherit";
      inputRef.current.style.height = "auto";
    }
  }, [input, inputRef]);

  // Send Typing Data Handler

  const updateTyping = useCallback(
    async (isTyping) => {
      if (isTyping) {
        const { data } = await supabase
          .from("chats")
          .update({ user_typing: true })
          .eq("id", chatId);

        console.log("Typing", data);
      } else {
        const { data } = await supabase
          .from("chats")
          .update({ user_typing: false })
          .eq("id", chatId);
        console.log("Not Typing", data);
      }
    },
    [chatId]
  );

  // Send Typing Data to Supabase
  useEffect(() => {
    if (typing === true) {
      updateTyping(true);
    } else if (typing === false) {
      updateTyping(false);
    }
  }, [typing, updateTyping]);

  // Typing Event Handler
  useEffect(() => {
    if (inputActive) {
      if (input.trim(" ").length > 0) {
        setTyping(true);
        // Clear typing after a second
        const timer = setTimeout(async () => {
          setTyping(false);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setTyping(false);
      }
    }
  }, [input, inputActive]);

  // Enter keydown Event Listener
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        if (input.length > 0) formRef.current.click();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [input]);

  // IMAGE UPLOAD

  const [uploading, setUploading] = useState(false);

  // Error handling
  const onDropRejected = useCallback((errors) => {
    console.log(errors);
    errors.forEach((error) => {
      toast.error(() => {
        return (
          <ErrorToasterChat
            code={error.errors[0].code}
            filename={error.file.name}
          />
        );
      });
    });
  }, []);

  // Upload handling
  const onDrop = useCallback((acceptedFiles) => {
    const formData = new FormData();
    acceptedFiles.forEach((file) => {
      formData.append("file", file);
    });
    if (acceptedFiles.length > 0) {
      try {
        setUploading(true);
        axios
          .post(
            process.env.NEXT_PUBLIC_DIRECTUS_URL +
              "/files?access_token=" +
              "691d8264fd0814d074d3f9b63efd6156",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async (res) => {
            setUploading(false);
            if (
              res.data.data.type === "image/jpeg" ||
              res.data.data.type === "image/png"
            ) {
              try {
                await supabase.from("messages").insert({
                  message: `<div><img src="${
                    process.env.NEXT_PUBLIC_IMAGE_URL + res.data.data.id
                  }" alt="${
                    res.data.data.title
                  }" /><span className="mt-1 text-xs">${
                    res.data.data.title
                  }</span></div>`,
                  chat_id: chatId,
                  user_id: supabaseUser.id,
                  avatar: user
                    ? user.avatar
                    : "992e52cb-eb9e-4081-9c1a-19355c8c9045",
                });
                // Increment new message count
                await supabase.rpc("increment_new_message_agent", {
                  row_id: chatId,
                });
              } catch (error) {
                console.log(error);
                errorToast(locale);
              }
            } else {
              try {
                // Increment new message count
                await supabase.rpc("increment_new_message_agent", {
                  row_id: chatId,
                });
                await supabase.from("messages").insert({
                  message: `<div type="file" id="${res.data.data.id}" name="${res.data.data.title}" size="${res.data.data.filesize}" extension="${res.data.data.type}">${res.data.data.title}</div>`,
                  chat_id: chatId,
                  user_id: supabaseUser.id,
                  type: "file",
                  avatar: user
                    ? user.avatar
                    : "992e52cb-eb9e-4081-9c1a-19355c8c9045",
                });
              } catch (error) {
                console.log(error);
                errorToast(locale);
              }
            }
          });
      } catch (error) {
        setUploading(false);
        console.log(error.response);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected,
    accept:
      "image/jpeg, image/png, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx",
    maxFiles: 1,
    maxSize: 1024 * 1024 * 5,
    noClick: true,
    noKeyboard: true,
  });

  return (
    <LazyMotion features={domAnimation}>
      <m.form
        variants={formVariants}
        initial="hidden"
        animate="show"
        onSubmit={messageHandler}
        className={`w-full fixed bottom-0 sm:static  flex items-center px-5 py-4 sm:rounded-b-md sm:rounded-l-md rounded-r-none rounded-t-none sm:rounded-t-none ${
          isDragActive
            ? "bg-emerald-50 border-t-emerald-600 border-t border-dashed"
            : "border-t bg-white border-t-gray-200"
        }`}
        {...getRootProps()}
      >
        <textarea
          onChange={(e) => {
            setInputActive(true);
            setInput(e.target.value);
          }}
          rows={1}
          value={input}
          placeholder={getTranslatedText(
            locale,
            "Mesajınızı yazın...",
            "chatbox.input.placeholder"
          )}
          className="w-full pr-5 text-base whitespace-pre-wrap align-middle bg-transparent border-none outline-none resize-none no-scrollbar"
          type="text"
          ref={inputRef}
        />
        {!uploading ? (
          <button
            onClick={() => {
              open();
            }}
            type="button"
            className={`${isDragActive && "animate-bounce"} sm:self-start`}
          >
            <Attach size={18} strokeWidth={2} />
            <input {...getInputProps()} />
          </button>
        ) : (
          <svg
            className="w-6 h-6 animate-spin text-primaryLight"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
        )}
        {input.length > 0 && (
          <button
            ref={formRef}
            onClick={messageHandler}
            type="submit"
            className="ml-4 sm:self-start"
          >
            <Send size={18} />
          </button>
        )}
      </m.form>
    </LazyMotion>
  );
};

export default ChatboxInput;
