import makeUpperCase from "../../../libs/makeUpperCase";
import ChatMessage from "./ChatMessage";

const ChatMessageDateGroup = ({
  date,
  messages,
  locale,
  supabaseUser,
  translation,
}) => {
  return (
    <div className="flex flex-col">
      <h4 className="inline-block px-2 py-1 mb-6 text-xs rounded-md bg-neutral-100 text-neutral-600 place-self-center">
        {makeUpperCase(date)}
      </h4>
      <ul className="flex flex-col gap-4">
        {messages.map((message) => (
          <ChatMessage
            key={message.id}
            id={message.id}
            avatar={message.avatar}
            message={message.message}
            translated_message={message.translated_message}
            time={message.created_at}
            userMessage={message.user_id === supabaseUser.id}
            locale={locale}
            seen={message.seen}
            initial={message.initial}
            chat_id={message.chat_id}
            type={message.type}
            translation={translation}
          />
        ))}
      </ul>
    </div>
  );
};

export default ChatMessageDateGroup;
