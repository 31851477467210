import getTranslatedText from "../../../libs/getTranslatedText";
import { useRouter } from "next/router";

const ErrorToasterChat = ({ code, filename }) => {
  const { locale } = useRouter();

  if (code === "too-many-files") {
    return (
      <div>
        <div>
          {getTranslatedText(
            locale,
            "Tek seferde tek bir dosya gönderebilirsin.",
            "imageUploader.errors.tooManyFilesChat"
          )}
        </div>
        <span className="text-xs mt-1">{filename}</span>
      </div>
    );
  } else if (code === "file-too-large") {
    return (
      <div>
        <div>
          {getTranslatedText(
            locale,
            "Dosya çok büyük. En fazla 5MB olmalı.",
            "imageUploader.errors.fileTooLargeChat"
          )}
        </div>
        <span className="text-xs mt-1">{filename}</span>
      </div>
    );
  } else if (code === "file-invalid-type") {
    return (
      <div>
        <div>
          {getTranslatedText(
            locale,
            "Yanlış dosya tipi. Görsel olarak JPG ya da PNG, dosya olarak ise PDF, DOC ya da DOCX olmalı.",
            "imageUploader.errors.fileTypeInvalidChat"
          )}
        </div>
        <span className="text-xs mt-1">{filename}</span>
      </div>
    );
  } else {
    return (
      <div>
        {getTranslatedText(locale, "Bir problem oluştu!", "toast.error")}
      </div>
    );
  }
};

export default ErrorToasterChat;
