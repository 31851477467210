import chatboxContext from "../../../context/chatboxContext";
import useUser from "../../../hooks/useUser";
import getParsedHtml from "../../../libs/getParsedHtml";
import messageSound from "../../../public/message.mp3";
import supabase from "../../../supabase/client";
import Chatbox from "../../modules/chatbox/Chatbox";
import ChatboxButton from "../../modules/chatbox/ChatboxButton";
import ChatboxLoader from "../loaders/ChatboxLoader";
import { RealtimeClient } from "@supabase/realtime-js";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { atom, useAtom } from "jotai";
import dynamic from "next/dynamic";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";
import ReactDOM from "react-dom";

const CTAMessage = dynamic(() => import("../../modules/chatbox/CTAMessage"));
const Image = dynamic(() => import("next/image"));
const CircleAlert = dynamic(() =>
  import("akar-icons").then((mod) => mod.CircleAlert)
);
export const chatBoxPopupAtom = atom({
  show: false,
  ctaMessage: false,
  opened: false,
});

const mountedAtom = atom(false);
const messagesAtom = atom([]);
const ctaMessageAtom = atom(true);
export const socketAtom = atom(null);
export const channelAtom = atom(null);
const connectionErrorAtom = atom(false);
export const channelErrorAtom = atom(false);

// Socket Client
const socket = new RealtimeClient(
  process.env.REALTIME_URL ||
    "wss://mivmimqdxjsbqywjspao.supabase.co/realtime/v1/websocket?vsn=1.0.0",
  {
    params: {
      apikey: process.env.NEXT_PUBLIC_SUPABASE_SECRET,
    },
  }
);

const ChatBoxPopup = () => {
  const [chatboxState, setChatboxState] = useAtom(chatboxContext);
  const [chatBox, setChatBox] = useAtom(chatBoxPopupAtom);
  const [mounted, setMounted] = useAtom(mountedAtom);
  const [ctaMessageState, setCtaMessageState] = useAtom(ctaMessageAtom);
  const [channelSubscription, setChannelSubcription] = useAtom(channelAtom);
  const [socketClient, setSocketClient] = useAtom(socketAtom);
  const [connectionError, setConnectionError] = useAtom(connectionErrorAtom);
  const [, setChannelError] = useAtom(channelErrorAtom);
  const [messages, setMessages] = useAtom(messagesAtom);
  const { user } = useUser();
  const supabaseUser = supabase.auth.user();
  const supabaseSession = supabase.auth.session();

  // Add Window Events
  useEffect(() => {
    if (chatBox.opened) {
      // Before Unload
      window.addEventListener("beforeunload", async (e) => {
        e.preventDefault();
        // Change Chat's Status Online
        await supabase
          .from(`chats`)
          .update({ user_online: false })
          .eq("id", chatboxState.chatId);
        e.returnValue = "";
        return "";
      });

      // Offline
      window.addEventListener("offline", () => {
        setConnectionError(true);
      });

      // Online
      window.addEventListener("online", () => {
        setConnectionError(false);
      });

      return () => {
        window.removeEventListener("beforeunload", async (e) => {
          e.preventDefault();
          // Change Chat's Status Online
          await supabase
            .from(`chats`)
            .update({ user_online: false })
            .eq("id", chatboxState.chatId);
          e.returnValue = "";
          return "";
        });

        window.removeEventListener("offline", () => {
          setConnectionError(true);
        });

        window.removeEventListener("online", () => {
          setConnectionError(false);
        });
      };
    }
  }, [chatBox.opened, chatboxState.chatId, setConnectionError]);

  // Build Realtime Connection
  useEffect(() => {
    if (chatBox.opened) {
      socket.connect();
      // On Successful Connection
      socket.onOpen(async () => {
        setConnectionError(false);
        setSocketClient(socket);
      });

      // On Disconnect
      socket.onClose(async () => {
        console.log("Socket Closed");
        setSocketClient(null);
        setConnectionError(true);
      });
      // On Error
      socket.onError(async (err) => {
        console.log(err);
        setConnectionError(true);
      });
      return async () => {
        socket.disconnect();
      };
    }
  }, [chatBox.opened, setChannelError, setConnectionError, setSocketClient]);

  // Connect Channel From Outside
  useEffect(() => {
    if (chatboxState.chatId && supabaseSession?.access_token) {
      // Message Channel Instance
      const channel = socketClient?.channel(
        `realtime:public:messages:chat_id=eq.${chatboxState.chatId}`,
        { user_token: supabaseSession?.access_token }
      );
      // Subscribe to Channel
      channel
        ?.subscribe()
        .receive("ok", async () => {
          setChannelSubcription(channel);
          setChannelError(false);
          // Change Chat's Status Online
          await supabase
            .from(`chats`)
            .update({ user_online: true })
            .eq("id", chatboxState.chatId);
        })
        .receive("error", async () => {
          setChannelError(true);
          console.log("Unable to subscribe to channel");
          await supabase
            .from(`chats`)
            .update({ user_online: false })
            .eq("id", chatboxState.chatId);
        })
        .receive("timeout", async () => {
          setChannelError(true);
          console.log("Network timeout");
          await supabase
            .from(`chats`)
            .update({ user_online: false })
            .eq("id", chatboxState.chatId);
        });
      return () => {
        channel?.unsubscribe();
        setChannelSubcription(null);
      };
    }
  }, [
    chatboxState.chatId,
    setChannelError,
    setChannelSubcription,
    socketClient,
    supabaseSession?.access_token,
  ]);

  // Handle Message Events for Outside Channel
  useEffect(() => {
    if (channelSubscription && !chatBox.show) {
      const ref = channelSubscription.on("*", (event) => {
        if (event.type === "INSERT") {
          new Audio(messageSound).play();
          setMessages((prev) => [...prev, event.record]);
        }
      });
      return () => {
        channelSubscription.off("*", ref);
      };
    }
  }, [channelSubscription, chatBox.show, setMessages]);

  // CTA Message Close Handler
  const ctaCloseHandler = () => {
    setChatBox((prev) => {
      return { ...prev, show: false, ctaMessage: false };
    });
    setCtaMessageState(false);
  };

  // CTA Open Handler
  const ctaOpenHandler = () => {
    setChatBox({ show: true, ctaMessage: false, opened: true });
    window?.dataLayer?.push({
      event: "chatCtaClick",
    });
  };

  // Delay CTA Message Mounting
  useEffect(() => {
    if (mounted && !chatBox.show && !chatBox.opened && ctaMessageState) {
      const timer = setTimeout(() => {
        setChatBox({ show: false, ctaMessage: true });
        setCtaMessageState(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [chatBox, ctaMessageState, mounted, setChatBox, setCtaMessageState]);

  // Escape keydown Event Listener
  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Escape") {
        setChatBox((prev) => {
          return { ...prev, show: false, ctaMessage: false };
        });
      }
    };

    if (mounted) {
      document.addEventListener("keydown", listener);
    } else {
      setMounted(true);
    }

    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [mounted, setMounted, setChatBox]);

  if (mounted) {
    return ReactDOM.createPortal(
      <div
        className={`fixed ${
          !chatBox.show ? "bottom-6 right-8" : ""
        } sm:bottom-8 z-50 sm:right-12`}
      >
        <AnimatePresence>
          {chatBox.show && (
            <LazyMotion features={domAnimation}>
              <m.div
                key="chatbox"
                animate={{ y: 0, opacity: 1, transition: { duration: 0.1 } }}
                initial={{ y: isMobile ? 2 : 10, opacity: 0.9 }}
                exit={{
                  y: isMobile ? 0 : 10,
                  opacity: 0,
                  transition: { duration: isMobile ? 0 : 0.2 },
                }}
                className="relative z-50"
              >
                {!connectionError && socketClient && (
                  <Chatbox
                    socketClient={socketClient}
                    connectionError={connectionError}
                    closeHandler={setChatBox}
                    chatboxStatus={chatboxState}
                    setChatboxStatus={setChatboxState}
                    supabaseUser={supabaseUser}
                    supabaseToken={supabaseSession?.access_token}
                    user={user}
                  />
                )}
                {/* Initial Conection Loader */}
                {!connectionError && !socketClient && (
                  <div className="bg-white overscroll-none flex flex-col rounded-md w-screen h-screen sm:w-96 sm:h-auto sm:min-h-[600px] sm:max-h-[600px] shadow-md sm:mb-10 z-[100]">
                    <ChatboxLoader />
                  </div>
                )}
                {/* Connection Error */}
                {connectionError && (
                  <div className="bg-white overscroll-none sm:border sm:border-red-300 flex flex-col items-center justify-center rounded-md w-screen h-screen sm:w-96 sm:h-auto sm:min-h-[600px] sm:max-h-[600px] shadow-md sm:mb-10 z-[100]">
                    <div className="text-red-600">
                      <CircleAlert strokeWidth={2} size={32} />
                    </div>
                    <div className="px-6 text-center">
                      <h3 className="mt-4 text-lg font-medium">
                        İnternet Bağlantısında Bir Sorun Var.
                      </h3>
                      <p className="text-sm text-neutral-500">
                        Lütfen cihazınızın internet bağlantısını kontrol edin ya
                        da sayfayı yeniden yüklemeyi deneyin.
                      </p>
                    </div>
                  </div>
                )}
              </m.div>
            </LazyMotion>
          )}
          {/* CTA MESSAGE BOX */}
          {chatBox.ctaMessage &&
            ((new Date().getHours() >= 9 && new Date().getHours() <= 24) ||
              new Date().getHours() === 0) && (
              <CTAMessage
                closeHandler={ctaCloseHandler}
                startHandler={ctaOpenHandler}
              />
            )}
        </AnimatePresence>
        {/* Messages */}
        {messages && messages.length > 0 && !chatBox.show && (
          <div className="relative flex flex-col items-end gap-4 mb-6">
            <button
              onClick={() => {
                setMessages([]);
              }}
              className="flex items-center justify-center px-2 py-1 text-xs bg-white rounded-md shadow-md cursor-pointer text-neutral-500"
            >
              Kapat
            </button>
            {messages.map((message) => (
              <div
                className="flex items-center max-w-sm gap-4 px-4 py-3 font-medium transition-transform duration-200 ease-in-out bg-white rounded-md shadow-md cursor-pointer hover:translate-x-1"
                key={message.id}
                onClick={() => {
                  setChatBox({ show: true, ctaMessage: false, opened: true });
                  setMessages([]);
                }}
              >
                <div className="shrink-0">
                  <div className="w-8 h-8 overflow-hidden rounded-full">
                    <Image
                      src={
                        process.env.NEXT_PUBLIC_IMAGE_URL +
                        message.avatar +
                        "?key=avatar"
                      }
                      alt="avatar"
                      width={30}
                      height={30}
                      layout="responsive"
                      objectFit="cover"
                      objectPosition="center"
                    />
                  </div>
                </div>
                {getParsedHtml(message.translated_message || message.message)}
              </div>
            ))}
          </div>
        )}
        <ChatboxButton
          setShow={() => {
            setMessages([]);
            setChatBox((prev) => {
              return { ctaMessage: false, show: !prev.show, opened: true };
            });
          }}
          show={chatBox}
        />
      </div>,
      document.getElementById("full-modal-root")
    );
  } else {
    return null;
  }
};

export default ChatBoxPopup;
