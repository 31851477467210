import getLocalTimeChat from "../../../../libs/getLocalTimeChat";
import getTranslatedText from "../../../../libs/getTranslatedText";
import makeUpperCase from "../../../../libs/makeUpperCase";
import supabase from "../../../../supabase/client";
import PrimaryGreenButton from "../../../elements/buttons/PrimaryGreenButton";
import { motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useState } from "react";
import { stripHtml } from "string-strip-html";
import useSWR from "swr";

const ChatboxHome = ({ user, supabaseUser, locale, setState }) => {
  const [loading, setLoading] = useState(false);
  const [lastChats, setLastChats] = useState([]);
  const chatsFetcher = async () => {
    try {
      const { data, error } = await supabase
        .from("chats")
        .select(
          `id,last_message,last_active,user_id,responsible_agent("avatar","first_name"),translation`
        )
        .eq("user_id", supabaseUser.id)
        .order("last_active", { ascending: false })
        .limit(5);

      if (data && !error) return data;
    } catch (error) {
      return null;
    }
  };

  const { data: lastChatsData, error: lastChatsError } = useSWR(
    supabaseUser?.id || null + "chats",
    chatsFetcher
  );

  useEffect(() => {
    if (lastChatsData && !lastChatsError) {
      setLastChats(lastChatsData);
    }
  }, [lastChatsData, lastChatsError]);

  // New Chat Handler
  const handleNewChat = async () => {
    setLoading(true);

    // Check current chats (2 days check)
    const { data: currentChat, error: currentChatError } = await supabase
      .from("chats")
      .select(
        `id,responsible_agent("avatar","first_name"),translation,last_active`
      )
      .eq("user_id", supabaseUser.id)
      .gt("last_active", new Date(Date.now() - 172800000).toISOString())
      .lt("last_active", new Date().toISOString())
      .order("last_active", { ascending: false })
      .limit(1)
      .single();

    if (currentChat && !currentChatError) {
      setState((prev) => {
        return {
          ...prev,
          chatId: currentChat.id,
          page: "chat",
          activeChat: true,
          newChat: false,
          agentName: currentChat?.responsible_agent?.first_name,
          translation: currentChat?.translation,
        };
      });
    } else {
      // If it's anonymous, get user data from supabase
      if (!user) {
        const { data: profileData } = await supabase
          .from("profiles")
          .select("first_name, last_name, avatar")
          .eq("id", supabaseUser.id)
          .single();

        // Create new chat
        const { data, error } = await supabase.from("chats").insert([
          {
            user_id: supabaseUser.id,
            user_first_name: profileData?.first_name,
            user_last_name: profileData?.last_name,
            user_avatar: profileData?.avatar,
            anonymous: true,
          },
        ]);

        // Set new chat state
        if (data && !error) {
          setLoading(false);
          setState((prev) => {
            return {
              ...prev,
              chatId: data[0].id,
              page: "chat",
              newChat: true,
              anonymous: true,
              activeChat: true,
            };
          });
        } else {
          setLoading(false);
          console.log(error);
          setState((prev) => {
            return {
              ...prev,
              page: "error",
            };
          });
        }
      }
      // If there is a user
      else {
        // Create new chat
        const { data, error } = await supabase.from("chats").insert([
          {
            user_id: supabaseUser.id,
            user_first_name: user?.first_name,
            user_last_name: user?.last_name,
            user_avatar: user?.avatar,
          },
        ]);
        // Set new chat state
        if (data && !error) {
          setLoading(false);
          setState((prev) => {
            return {
              ...prev,
              chatId: data[0].id,
              page: "chat",
              newChat: true,
              activeChat: true,
            };
          });
        } else {
          setLoading(false);
          console.log(error);
          setState((prev) => {
            return {
              ...prev,
              page: "error",
            };
          });
        }
      }
    }
  };

  // Animation Stages
  const container = {
    hidden: { opacity: 0, x: -10 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, x: -10 },
    show: { opacity: 1, x: 0 },
  };

  return (
    <div className="px-5 py-10 overflow-x-hidden overflow-y-scroll">
      <div className="flex items-center flex-1 max-w-full">
        <motion.div
          variants={container}
          initial="hidden"
          animate="show"
          className="pt-8"
        >
          <motion.h3 variants={item} className="text-2xl">
            {getTranslatedText(locale, "Hoşgeldin", "chatbox.home.title")}
            {user ? ", " + user.first_name : null}
            <span> 👋</span>
          </motion.h3>
          <motion.p variants={item} className="mt-2 text-neutral-600">
            {getTranslatedText(
              locale,
              "Sana destek olmak için buradayız. Sohbetine kaldığın yerden devam et ya da yenisini başlat.",
              "chatbox.home.description"
            )}
          </motion.p>
          <motion.div
            variants={item}
            className={`py-6 ${
              lastChats.length > 0 && "border-b border-b-gray-100"
            }`}
          >
            <PrimaryGreenButton
              handler={handleNewChat}
              handling={loading}
              size="small"
              full
            >
              {getTranslatedText(
                locale,
                "Sohbet Başlat",
                "chatbox.home.button"
              )}
            </PrimaryGreenButton>
            <motion.p variants={item} className="mt-4 text-xs text-neutral-500">
              {getTranslatedText(
                locale,
                "Ortalama Cevap Süresi: ",
                "chatbox.home.averageTime"
              )}
              <span className="text-neutral-800">
                {getTranslatedText(
                  locale,
                  "1-2 dakika.",
                  "chatbox.home.averageTimeText"
                )}
              </span>
            </motion.p>
          </motion.div>
          <motion.div variants={item}>
            {lastChats.length > 0 && (
              <div className="mt-6">
                <motion.h4 variants={item} className="mb-4 font-medium">
                  {getTranslatedText(
                    locale,
                    "Önceki Sohbetlerin",
                    "chatbox.home.previousChats"
                  )}
                </motion.h4>
                <motion.div variants={item} className="flex flex-col gap-3">
                  {lastChats.length > 0
                    ? lastChats.map((chat) => {
                        return (
                          <motion.div
                            key={chat.id}
                            variants={item}
                            onClick={() => {
                              setState((prev) => {
                                return {
                                  ...prev,
                                  chatId: chat.id,
                                  page: "chat",
                                  activeChat: true,
                                  agentName:
                                    chat?.responsible_agent?.first_name,
                                  translation: chat?.translation,
                                };
                              });
                            }}
                            className="flex items-center max-w-full gap-3 px-4 py-2 overflow-hidden transition-colors duration-100 ease-out border border-gray-100 rounded-md cursor-pointer hover:border-primaryLight"
                          >
                            <div className="shrink-0">
                              <div className="w-8 h-8 overflow-hidden rounded-full">
                                <Image
                                  src={`${process.env.NEXT_PUBLIC_IMAGE_URL}${
                                    chat.responsible_agent?.avatar ||
                                    "d6fa1bf6-c0af-4c2b-a82c-5761dc0a9e30"
                                  }?key=avatar`}
                                  alt="avatar"
                                  width={30}
                                  height={30}
                                  layout="responsive"
                                  objectFit="cover"
                                  objectPosition="center"
                                />
                              </div>
                            </div>
                            <div className="flex flex-col max-w-full text-sm">
                              <span className="font-medium">
                                {chat?.responsible_agent?.first_name ||
                                  "agrowy"}
                              </span>
                              <span className="text-xs text-neutral-500 max-w-[180px] truncate">
                                {stripHtml(chat.last_message).result}
                              </span>
                            </div>

                            <div className="ml-auto">
                              <div className="text-xs font-light">
                                {makeUpperCase(
                                  getLocalTimeChat(
                                    locale,
                                    chat.last_active,
                                    true
                                  )
                                )}
                              </div>
                            </div>
                          </motion.div>
                        );
                      })
                    : null}
                </motion.div>
              </div>
            )}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ChatboxHome;
