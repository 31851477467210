import getTranslatedText from "../../../libs/getTranslatedText";
import MiniIconGreen from "../../elements/brand/MiniIconGreen";
import { ChevronLeft, CircleX } from "akar-icons";
import { AnimatePresence, LazyMotion, domAnimation, m } from "framer-motion";
import { useState } from "react";

const ChatboxHeader = ({ closeHandler, setState, state, locale }) => {
  const [padding, setPadding] = useState(false);

  const button = {
    hidden: { opacity: 0, x: 10 },
    show: {
      opacity: 1,
      x: 0,
    },
    exit: { opacity: 0, x: -10, transition: { duration: 0.2 } },
  };

  const logo = {
    initial: { opacity: 0, x: -10 },
    animate: { opacity: 1, x: 0 },
  };

  return (
    <LazyMotion features={domAnimation}>
      <div
        className={`py-3 px-5 bg-neutral-900 flex items-center justify-start gap-4 sm:rounded-t-md sm:rounded-r-md rounded-l-none rounded-b-none sm:rounded-b-none w-auto transition-all duration-500`}
      >
        <AnimatePresence
          onExitComplete={() => {
            setPadding(false);
          }}
        >
          {state.page === "chat" && (
            <m.button
              variants={button}
              initial="hidden"
              animate="show"
              exit="exit"
              onClick={() => {
                setTimeout(() => {
                  setPadding(true);
                }, 200);

                setState((prev) => {
                  return { ...prev, page: "home", agentName: "" };
                });
              }}
              className="flex items-center self-stretch justify-center min-h-full px-3 py-2 text-white transition-colors duration-100 ease-out rounded-md cursor-pointer bg-neutral-800 hover:bg-neutral-700"
            >
              <ChevronLeft size={18} />
            </m.button>
          )}
        </AnimatePresence>
        <m.div
          variants={logo}
          initial={"initial"}
          animate={"animate"}
          className={`flex items-center justify-self-auto gap-4 transition-all duration-200 ease-out ${
            padding ? "-translate-x-20" : "translate-x-0"
          }`}
        >
          <div className="px-3 py-2 bg-white rounded-md">
            <MiniIconGreen />
          </div>
          <div className="flex flex-col">
            <span className="text-sm text-gray-50 mb-[2px]">agrowy</span>
            <div className="flex items-center gap-2">
              {/* Little Icon to show if we are online or not */}
              <div
                className={`w-2 h-2 rounded-full bg-primaryLight ${
                  new Date().getHours() >= 9 && new Date().getHours() <= 20
                    ? "bg-primaryLight"
                    : "bg-pink-600"
                }`}
              ></div>
              <span className="text-xs text-neutral-400">
                {state.agentName ||
                  getTranslatedText(
                    locale,
                    "Çözüm Merkezi",
                    "chatbox.header.title"
                  )}
              </span>
            </div>
          </div>
        </m.div>
        <div
          onClick={() => {
            closeHandler((prev) => {
              return { ...prev, show: false };
            });
          }}
          className="ml-auto cursor-pointer text-neutral-400"
        >
          <CircleX size={16} />
        </div>
      </div>
    </LazyMotion>
  );
};

export default ChatboxHeader;
