const MiniIconGreen = (props) => {
  const { size = "mini" } = props;

  if (size === "mini") {
    return (
        <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.9873 0.959991C10.9873 3.71868 12.1058 6.21879 13.9189 8.03191C15.732 9.84655 18.2336 10.9635 21 10.9635C21 5.43999 16.52 0.959991 10.9873 0.959991Z" fill="#D6EF1D"/>
        <path d="M10.9873 21.9401C13.7536 21.9401 16.2537 20.8216 18.0684 19.0085C19.883 17.1954 21 14.6938 21 11.9366C15.4688 11.9366 10.9873 16.4166 10.9873 21.9401Z" fill="#00939E"/>
        <path d="M0.00149536 11.9366C0.00149536 17.4601 4.4815 21.9401 10.0142 21.9401C10.0142 19.183 8.8957 16.6813 7.08259 14.8682C5.26947 13.0536 2.76783 11.9366 0.00149536 11.9366Z" fill="#70CD70"/>
        <path d="M10.0126 0.959991C7.24631 0.959991 4.7462 2.07846 2.93156 3.89158C1.11691 5.70469 -3.05176e-05 8.20633 -3.05176e-05 10.9635C5.53112 10.9635 10.0126 6.48349 10.0126 0.959991Z" fill="#337F5A"/>
        </svg>
    );
  }
  if (size === "medium") {
    return (
      <svg width="45" height="46" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.9873 0.959991C10.9873 3.71868 12.1058 6.21879 13.9189 8.03191C15.732 9.84655 18.2336 10.9635 21 10.9635C21 5.43999 16.52 0.959991 10.9873 0.959991Z" fill="#D6EF1D"/>
<path d="M10.9873 21.9401C13.7536 21.9401 16.2537 20.8216 18.0684 19.0085C19.883 17.1954 21 14.6938 21 11.9366C15.4688 11.9366 10.9873 16.4166 10.9873 21.9401Z" fill="#00939E"/>
<path d="M0.00149536 11.9366C0.00149536 17.4601 4.4815 21.9401 10.0142 21.9401C10.0142 19.183 8.8957 16.6813 7.08259 14.8682C5.26947 13.0536 2.76783 11.9366 0.00149536 11.9366Z" fill="#70CD70"/>
<path d="M10.0126 0.959991C7.24631 0.959991 4.7462 2.07846 2.93156 3.89158C1.11691 5.70469 -3.05176e-05 8.20633 -3.05176e-05 10.9635C5.53112 10.9635 10.0126 6.48349 10.0126 0.959991Z" fill="#337F5A"/>
</svg>

    );
  } else {
    return null;
  }
};

export default MiniIconGreen;
