import { Download } from "akar-icons";
import Link from "next/link";

const FilePreview = ({ name, id, size }) => {
  return (
    <div className="flex flex-col items-centers gap-2">
      <div className="text-xs">{name}</div>
      <Link
        href={`${process.env.NEXT_PUBLIC_IMAGE_URL + id}?download`}
        className="flex items-center justify-center  bg-primaryLight text-neutral-800 py-2 px-4 rounded-md"
        target="_blank"
        rel="noreferrer"
      >
        <Download size={18} />
        <span className="ml-3">Dosyayı İndir</span>
        <span className="text-xs font-light ml-1 place-self-end">
          {Math.round((size / 1048576) * 100) / 100 + "mb"}
        </span>
      </Link>
    </div>
  );
};

export default FilePreview;
