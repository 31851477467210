import supabase from "../../../../supabase/client";
import Promise from "promise";

/*  [
    { message: "Merhaba", delay: 500 },
    { message: "Destek ekibinden arkadaşımız bağlanmak üzere.", delay: 1500 },
    {
      message:
        "Görüşmemiz boyunca paylaşacağın kişisel verilerin KVKK kapsamında korunacağını ve görüşmeye devam ederek sözleşmeyi kabul ettiğini varsaydığımızı belirtelim.",
      delay: 2000,
    },
  ] */

const newChatSc = async ({
  chatId,
  actionList = [
    { message: "Merhaba", delay: 500 },
    { message: "Destek ekibinden arkadaşımız bağlanmak üzere.", delay: 1500 },
    {
      message:
        'Görüşmemiz boyunca paylaşacağın kişisel verilerin KVKK kapsamında korunacağını ve görüşmeye devam ederek <a href="/kvkk-kapsaminda-aydinlatma-metni">sözleşmeyi</a> kabul ettiğini varsaydığımızı belirtelim.',
      delay: 2000,
    },
  ],
  typingHandler,
}) => {
  // Typing effect for bot
  const typingEffect = async (time) => {
    typingHandler((prev) => {
      return { ...prev, typingEffect: true };
    });
    await new Promise((resolve) => setTimeout(resolve, time));
    typingHandler((prev) => {
      return { ...prev, typingEffect: false };
    });
  };

  // Send message to chat
  const sendMessage = async (message) => {
    await supabase.from("messages").insert({
      message,
      chat_id: chatId,
      avatar: "d6fa1bf6-c0af-4c2b-a82c-5761dc0a9e30",
      type: "bot",
    });
  };

  // Run the scenario
  actionList.map(async (message) => {
    return (
      await typingEffect(message.delay), await sendMessage(message.message)
    );
  });

  // Finish the scenario
  typingHandler((prev) => {
    return { ...prev, newChat: false };
  });
};

export default newChatSc;
