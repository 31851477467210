import ChatMessageList from "../ChatMessageList";
import ChatboxInput from "../ChatboxInput";

const ChatPage = ({
  user,
  supabaseUser,
  supabaseToken,
  chatId,
  locale,
  state,
  setState,
  newChat,
}) => {
  return (
    <>
      <div className="px-5 py-5 overflow-y-scroll h-[calc(100vh-100px)] bg-gray-50">
        <ChatMessageList
          chatId={chatId}
          newChat={newChat}
          user={user}
          locale={locale}
          supabaseUser={supabaseUser}
          supabaseToken={supabaseToken}
          setState={setState}
          state={state}
        />
      </div>
      <ChatboxInput
        chatId={chatId}
        supabaseUser={supabaseUser}
        locale={locale}
        user={user}
        setState={setState}
        state={state}
      />
    </>
  );
};

export default ChatPage;
