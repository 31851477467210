const makeUpperCase = (text, type = "first") => {
  if (type === "first") {
    return text.slice(0, 1).toUpperCase() + text.slice(1);
  } else if (type === "every") {
    const arr = text.split(" ");
    const newArr = arr.forEach((element) => {
      return element.slice(1).toUpperCase() + element.slice(1, -1);
    });
    return newArr.join(" ");
  } else {
    return text;
  }
};

export default makeUpperCase;
